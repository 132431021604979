* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: var(--seat);
}

::-webkit-scrollbar-track {
    background: transparent;
}

::selection {
    color: #fff;
    background: var(--theme);
}

body {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}

body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -520;
    pointer-events: none;
}

input[type='text'] {
    -webkit-appearance: none;
    border-radius: 0;
    font-size: 13px;
    font-weight: 500;
}

iframe {
    display: block;
    border: none;
    margin: 0 auto;
    vertical-align: middle;
}

textarea {
    font-size: 14px;
    resize: none;
    -webkit-appearance: none;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

img {
    border: 0;
    vertical-align: middle;
}

img[src=''],
img:not([src]) {
    border: 0;
    opacity: 0;
}

svg,
canvas {
    vertical-align: middle;
}

button {
    cursor: pointer;
    -webkit-appearance: none;
    font-size: 13px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.joe_main {
    min-width: 0;
    flex: 1;
    padding: 15px 0;
}

.joe_container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

@media (min-width: 576px) {
    .joe_container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .joe_container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .joe_container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .joe_container {
        max-width: 1140px;
    }
}
@media (min-width: 1400px) {
    .joe_container {
        max-width: 1320px;
    }
}
