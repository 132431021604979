/* 昼夜模式专用css */
/* 以下配色为全球公认的一套色彩值，如无必要，无需修改下方色彩值（主题色可以修改） */
html {
    --theme: #409eff;
    --background: #fff;
    --main: #303133;
    --routine: #606266;
    --minor: #909399;
    --seat: #c0c4cc;
    --classA: #dcdfe6;
    --classB: #e4e7ed;
    --classC: #ebeef5;
    --classD: #f2f6fc;
    --radius-wrap: 8px;
    --radius-inner: 4px;
    --text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    --box-shadow: 0px 0px 20px -5px rgba(158, 158, 158, 0.22);
}

/* 黑夜模式的配色方案 */
html[data-night='night'] body {
    --theme: #54b5db;
    --background: #232323;
    --main: #999;
    --routine: #888;
    --minor: #777;
    --seat: #666;
    --classA: #515253;
    --classB: #454545;
    --classC: #414243;
    --classD: #303030;
    --text-shadow: none;
    --box-shadow: none;
}

/* 此处用于修改黑夜模式下没有覆盖到的小地方的样式 */
html[data-night='night'] {
    /* 这里务必不能修改！！！ */
    body::before {
        background-color: #121212;
        z-index: -500;
    }
    .joe_header__above-logo img {
        display: none;
    }
    .joe_header__above-logo svg {
        display: block;
    }
    /* 自定义的样式，写在这个下面 */
    .joe_detail__article-video .episodes .box .item:not(.active) {
        background: var(--classC);
    }
    // .joe_detail__article blockquote {
    //     background: var(--classD);
    //     color: var(--routine);
    //     border-color: var(--classA);
    // }
    // .joe_detail__article code:not([class]) {
    //     background: #fdf0ec38;
    //     color: #a6aba8;
    // }
    .joe_detail__article-anote {
        color: #ffffff9c;
    }
    .joe_detail__article-abtn {
        color: #ffffff9c;
    }
    .joe_detail__overdue-wrapper {
        background: var(--classD);
    }
}
